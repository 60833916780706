export const quotes = [
    {
    "quote": "Nuk do vdesim kurrë, pasi që ideja jonë nuk mund të vdesë.",
    "author": "Ismail Kadare",
    "Translation": "We will never die, as our idea cannot die."
    },
    {
    "quote": "Shpirti i një populli gjendet në kulturën që ai krijojë.",
    "author": "Edi Rama",
    "Translation": "The spirit of a people is found in the culture it creates."
    },
    {
    "quote": "Nuk mund të kesh sukses pa e bërë punën me pasion dhe pa u përkushtuar plotësisht për atë që bënë.",
    "author": "Edi Rama",
    "Translation": "You cannot be successful without doing the work with passion and without being fully dedicated to what you do."
    },
    {
    "quote": "Çdo gjë në jetë vjen me një çmim, dhe atëherë nuk duhet të frikësohemi nga çmimi i ndryshëm i suksesit.",
    "author": "Edi Rama",
    "Translation": "Everything in life comes with a price, and so we should not be afraid of the different price of success."
    },
    {
    "quote": "Nëse nuk bëjmë asgjë ndryshe, nuk do të ndryshojë asgjë.",
    "author": "Edi Rama",
    "Translation": "If we don't do anything differently, nothing will change."
    },
    {
    "quote": "Drejtesia është pamundur pa besim.",
    "author": "Edi Rama",
    "Translation": "Justice is impossible without trust."
    },
    {
    "quote": "Shpirti ynë është lindur i lirë, dhe do të mbetet i tillë nën çdo okupim, kërcënim, ose dhunë.",
    "author": "Ibrahim Rugova",
    "Translation": "Our spirit is born free, and it will remain so under any occupation, threat, or violence."
    },
    {
    "quote": "Më së shumti, e ardhmja është e rëndësishme për ata që kanë ndikim në të tashmen.",
    "author": "Motrat Qiriazi",
    "Translation": "Most importantly, the future is important for those who have an impact on the present."
    },
    {
    "quote": "Puna është kënaqësi dhe krenari, pa punë nuk ka vlerë dhe kjo është e vërtetë për çdo qenie njerëzore.",
    "author": "Mother Teresa",
    "Translation": "Work is a pleasure and pride, without work there is no value, and this is true for every human being."
    },
    {
    "quote": "Historia nuk është vetëm përmbledhje e fakteve të kaluar, por edhe një nga mësueset më të mira të jetës.",
    "author": "Ismail Kadare",
    "Translation": "History is not only a summary of past facts, but also one of life's best teachers."
    },
    {
    "quote": "Gjuha është një burim i vërtetë i ndjenjave dhe mendimeve tona.",
    "author": "Ibrahim Rugova",
    "Translation": "Language is a true source of our feelings and thoughts."
    },    
    {
    "quote": "Dua të ndjehem i pasur, jo për shkak të pasurisë, por për shkak të vlerës së njerëzve me të cilët unë ndaj jetën time.",
    "author": "Ismail Kadare",
    "Translation": "I want to feel rich, not because of wealth, but because of the value of the people with whom I share my life."
    },
    {
    "quote": "Mos u tremb nga asgjë në jetë, sepse në fund të ditës, gjithçka do të ndryshojë, dhe vetëm ato që ke bërë me dashuri do të jetojnë në mendjen e njerëzve.",
    "author": "Mother Teresa",
    "Translation": "Do not be afraid of anything in life, because at the end of the day, everything will change, and only those things that you have done with love will live on in people's minds."
    },
    {
    "quote": "Kur flasim, ne thjesht e përdorim gjuhën. Por sa pak e njohim atë gjuhë, sa pak e dimë të kuptojmë misteret që fsheh në vetvete.",
    "author": "Ismail Kadare",
    "Translation": "When we speak, we simply use language. But how little we know that language, how little we understand the mysteries it hides within itself."
    },
    {
    "quote": "Dashuria është burimi i gjithë jetës sonë, dhe jo vetëm një ndjesi.",
    "author": "Naim Frashëri",
    "Translation": "Love is the source of our entire life, and not just a feeling."
    },
    {
    "quote": "Kur mendojmë që kemi shumë, ne vërtet e kemi shumë pak. Sepse kemi humbur çelësin e vërtetë të lumturisë.",
    "author": "Ismail Kadare",
    "Translation": "When we think we have a lot, we really have very little. Because we have lost the true key to happiness."
    },
    {
    "quote": "Jeta nuk është se sa herë frymëzon, por se sa herë të frymëzon koha.",
    "author": "Dritëro Agolli",
    "Translation": "Life is not about how many times you breathe, but about how many times time takes your breath away."
    },
    {
    "quote": "Nëse ke një ide, nuk duhet ta fshehësh atë, por duhet ta ndash me botën.",
    "author": "Mother Teresa",
    "Translation": "If you have an idea, you should not hide it, but share it with the world."
    },
    {
    "quote": "Mund të humbasim çdo gjë, por s’duhet kurrë të humbasim shpresën.",
    "author": "Mother Teresa",
    "Translation": "We may lose everything, but we should never lose hope."
    },
    {
    "quote": "Për të arritur diçka të madhe, duhet të ndjehesh i/e vogël.",
    "author": "Mother Teresa",
    "Translation": "To achieve something great, you must feel small."
    },
    {
    "quote": "Mund të flisni gjuhë të ndryshme, por në fund të ditës, të gjithë jemi njerëz dhe jemi të barabartë.",
    "author": "Ibrahim Rugova",
    "Translation": "You can speak different languages, but at the end of the day, we are all humans and we are equal."
    },
    {
    "quote": "Më e mira në jetë është të bëhesh vetë një person i mirë.",
    "author": "Mother Teresa",
    "Translation": "The best thing in life is to become a good person yourself."
    },
    {
    "quote": "Ne nuk jemi gjithmonë të barabartë, por duhet të jemi të barabartë para ligjit dhe në dinjitetin tonë të njeriut.",
    "author": "Ibrahim Rugova",
    "Translation": "We are not always equal, but we must be equal before the law and in our human dignity."
    },
    {
    "quote": "Sado larg të jemi, nuk kemi harruar asnjëherë rrënjët tona.",
    "author": "Ismail Kadare",
    "Translation": "No matter how far we are, we have never forgotten our roots."
    },
    {
    "quote": "Zemra ime është shqiptare, por shtëpia ime është botërore.",
    "author": "Mother Teresa",
    "Translation": "My heart is Albanian, but my home is global."
    },
    {
    "quote": "Nëse s'don me thënë kurrë gjë që të ndjehet ajo, nuk mund të thuash asgjë me kuptim.",
    "author": "Ismail Kadare",
    "Translation": "If you never want to say something that feels it, you can't say anything meaningful."
    },
    {
    "quote": "Mund të rrëzohet një vesh, por një zë që bën zërin e shqiptarit nuk mund të shuhet kurrë.",
    "author": "Ismail Qemali",
    "Translation": "An ear may fall, but a voice that speaks for the Albanian people can never be silenced."
    },
    {
    "quote": "Kur luhet kënga e mirë, s'i kthehet kurrë shpinën.",
    "author": "Dritëro Agolli",
    "Translation": "When a good song is played, it never turns its back."
    },
    {
    "quote": "Gjithmonë krenar për traditën dhe kombin, jo për njerëzit dhe idetë që i shkelin ato.",
    "author": "Naim Frashëri",
    "Translation": "Always proud of tradition and nation, not of people and ideas that violate them."
    },
    {
    "quote": "Shqiptarët janë një komb i durueshëm, dhe ajo që i ka mbajtur pranë në kohët më të vështira është dashuria për lirinë.",
    "author": "Ibrahim Rugova",
    "Translation": "Albanians are a patient nation, and what has kept them close in the hardest times is the love for freedom."
    },
    {
    "quote": "Historia jonë është më e mira në botë, por ne jemi më të këqijtë se historia jonë.",
    "author": "Dritëro Agolli",
    "Translation": "Our history is the best in the world, but we are worse than our history."
    },
    {
    "quote": "Sa herë që një shqiptar vdes, bëhemi më të dobët.",
    "author": "Ismail Kadare",
    "Translation": "Every time an Albanian dies, we become weaker."
    },
    {
    "quote": "Shqiptarët janë si ujërat; gjithmonë e gjejnë rrugën e tyre.",
    "author": "Ismail Kadare",
    "Translation": "Albanians are like water; they always find their way."
    },
    {
    "quote": "Në këtë botë, për një shqiptar, nuk ka gjë më e bukur se liria.",
    "author": "Ismail Kadare",
    "Translation": "In this world, there is nothing more beautiful for an Albanian than freedom."
    },
    {
    "quote": "Nëse kërkon për mua, kërko në zemrën e një fëmije.",
    "author": "Mother Teresa",
    "Translation": "If you seek me, seek me in the heart of a child."
    },
    {
    "quote": "Liria nuk është e shtrenjtë. E shtrenjtë është e dëshmuar që nuk jemi të lirë.",
    "author": "Adem Demaçi",
    "Translation": "Freedom is not expensive. What is expensive is to prove that we are not free."
    },
    {
    "quote": "Dashuria është ajo që bën botën të rrotullohet.",
    "author": "Pjetër Bogdani",
    "Translation": "Love is what makes the world go round."
    },
    {
    "quote": "Nëse jeton vetëm për veten, je vetëm. Nëse jeton për të tjerët, do të jesh i përjetshëm.",
    "author": "Mother Teresa",
    "Translation": "If you live only for yourself, you are alone. If you live for others, you will be eternal."
    },
    {
    "quote": "Gjuha është e varfër për t'i shprehur ndjenjat më të thella të njeriut.",
    "author": "Ismail Kadare",
    "Translation": "Language is poor in expressing the deepest feelings of human beings."
    },
    {
    "quote": "Kush është ai që mund ta ndalë rrotullimin e qiellit dhe tokës?",
    "author": "Ismail Kadare",
    "Translation": "Who can stop the rotation of the sky and the earth?"
    },
    {
    "quote": "Nuk ekziston ndonjë mjet më i fuqishëm për t'i ndihmuar njerëzit, se sa të ndjehesh vërtet i interesuar për ta.",
    "author": "Mother Teresa",
    "Translation": "There is no more powerful tool to help people than to truly feel interested in them."
    },
    {
    "quote": "Mund ta luftosh armikun, por s'ke asnjë shans kundër miqve të tu.",
    "author": "Ibrahim Rugova",
    "Translation": "You may fight against your enemy, but you have no chance against your friends."
    },
    {
    "quote": "Vdekja nuk është mbyllje, por hapje drejt një jetë tjetër.",
    "author": "Mother Teresa",
    "Translation": "Death is not a closure, but an opening towards another life."
    },
    {
    "quote": "Të gjithë besojmë në një botë të drejtë, por duhet të punojmë për ta ndërtuar atë.",
    "author": "Ismail Kadare",
    "Translation": "We all believe in a just world, but we must work to build it."
    },
    {
    "quote": "Dashuria është një gjë e thjeshtë, por njerëzit e bëjnë të komplikuar.",
    "author": "Dritëro Agolli",
    "Translation": "Love is a simple thing, but people make it complicated."
    },
    {
    "quote": "Fjalet mund të jenë të heshtura, por asgjë nuk mund t'i ndalë pikëllimin e tyre.",
    "author": "Ismail Kadare",
    "Translation": "Words may be silent, but nothing can stop their sadness."
    },
    {
    "quote": "Mund të jesh më i fortë se të mendosh, mund të jesh më i mençur se të besosh, mund të jesh më i afërt se të tregoni, por pa dashuri nuk mund të jesh asgjë.",
    "author": "Pjetër Bogdani",
    "Translation": "You can be stronger than you think, wiser than you believe, closer than you show, but without love you can be nothing."
    },
    {
    "quote": "Dy këmbët e para janë më të rëndësishmet, sepse ata që nuk i kenë ato nuk mund të bëjnë asgjë.",
    "author": "Adem Jashari",
    "Translation": "The first two feet are the most important, because those who do not have them cannot do anything."
    },
    {
    "quote": "Shumica e njerëzve vuajnë nga kujtesa e një jete të shkuar, por të gjithë duhet të shohim përpara.",
    "author": "Ismail Kadare",
    "Translation": "Most people suffer from the memory of a past life, but we all must look forward."
    },
    {
    "quote": "Nëse të tjerët mund të bëjnë, pse nuk mundesh edhe ti?",
    "author": "Mother Teresa",
    "Translation": "If others can do it, why can't you?"
    },
    {
    "quote": "Liria është pasuria më e shtrenjtë e njeriut.",
    "author": "Adem Jashari",
    "Translation": "Freedom is the most valuable asset of humanity."
    },
    {
    "quote": "Koha ka ardhur që të ndryshojmë ne vetë dhe koha është edhe më e fortë se pushkatimi dhe torturat. Kjo është arsyeja që duhet të ndryshojmë ne vetë dhe jo vetëm shtetin tonë.",
    "author": "Fatos Lubonja",
    "Translation": "The time has come for us to change ourselves, and time is even stronger than punishment and torture. That is why we must change ourselves and not just our state."
    },
    {
    "quote": "Këmbët tona janë të lodhura, por shpirti ynë është akoma në lëvizje.",
    "author": "Ibrahim Rugova",
    "Translation": "Our feet may be tired, but our spirit is still moving."
    },
    {
    "quote": "Nuk do të kemi të njëjtin drejtor, por do të kemi të njëjtin popull.",
    "author": "Ismail Kadare",
    "Translation": "We may not have the same leader, but we will have the same people."
    },
    {
    "quote": "Kosova është vendi im, kudo që do të shkoj, do të jem në zemër të saj.",
    "author": "Ibrahim Rugova",
    "Translation": "Kosovo is my country, wherever I go, I will be in its heart."
    },
    {
    "quote": "Liria është ajo gjë e çmuar që nuk mund ta blen askush, por të gjithë mund ta humbin.",
    "author": "Ibrahim Rugova",
    "Translation": "Freedom is the precious thing that no one can buy, but everyone can lose."
    },
    {
    "quote": "Duket sikur kemi bërë një hap, por kemi marrë dhunë gjithmonë, nuk është një hap në tërësinë e lirisë sonë.",
    "author": "Adem Demaçi",
    "Translation": "It seems like we have taken a step, but we have always faced violence, it is not a step towards our complete freedom."
    },
    {
    "quote": "Më e rëndësishmja është të jesh vullnetar i lirisë dhe të kuptosh se çfarë do të thotë kjo.",
    "author": "Adem Demaçi",
    "Translation": "The most important thing is to be a volunteer of freedom and to understand what this means."
    },
    {
    "quote": "Liria është ajo që nuk mund ta shijosh plotësisht, deri sa ta humbasësh atë.",
    "author": "Ibrahim Rugova",
    "Translation": "Freedom is something that you cannot fully enjoy until you lose it."
    },
    {
    "quote": "Nëse dëshiron të arrish diçka, duhet të punosh fort dhe të mos dorëzohesh kurrë.",
    "author": "Adem Jashari",
    "Translation": "If you want to achieve something, you have to work hard and never give up."
    },
    {
    "quote": "Fjala më e bukur në gjuhën shqipe është Liria.",
    "author": "Naim Frashëri",
    "Translation": "The most beautiful word in Albanian language is Liberty."
    },
    {
    "quote": "Në jetë, suksesin e arrin vetëm ai që nuk ndalet kurrë të punojë fort për atë që dëshiron të arrijë.",
    "author": "Luljeta Lleshanaku",
    "Translation": "In life, only those who never give up working hard for what they want, achieve success."
    },
    {
    "quote": "Vetëm duke u bashkuar dhe duke punuar së bashku, ne mund të ndërtojmë një të ardhme të ndritur për vendin tonë.",
    "author": "Ibrahim Rugova",
    "Translation": "Only by uniting and working together, we can build a bright future for our country."
    },
    {
    "quote": "Nëse nuk jemi të bashkuar, ne nuk mund të arrinim asgjë. Një popull i bashkuar është një popull i fortë.",
    "author": "Ismail Kadare",
    "Translation": "If we are not united, we cannot achieve anything. A united people is a strong people."
    },
    {
    "quote": "Liria është më e çmuara ndër të gjitha vlerat. Pa liri, askush nuk mund të jetojë si një qenie njerëzore të plotë.",
    "author": "Adem Demaçi",
    "Translation": "Liberty is the most precious among all values. Without liberty, no one can live as a complete human being."
    },
    {
    "quote": "Kur lufton për diçka, nuk duhet të dorëzohesh kurrë, edhe në momentet më të vështira.",
    "author": "Mother Teresa",
    "Translation": "When you fight for something, you should never give up, even in the toughest moments."
    },
    {
    "quote": "Koha e sotme është e jona. Duhet ta përdorim atë me kujdes dhe me vendosmëri për të ndërtuar një të ardhme më të mirë.",
    "author": "Ibrahim Rugova",
    "Translation": "The present time is ours. We must use it carefully and with determination to build a better future."
    },
    {
    "quote": "Mos u tremb nga vdekja, por nga mos jetësimi i asaj që ke ndërtuar në këtë jetë.",
    "author": "Mother Teresa",
    "Translation": "Do not be afraid of death, but be afraid of not fulfilling what you have built in this life."
    },
    {
    "quote": "Të ndryshosh një vend, duhet ti ndryshosh njerëzit e tij.",
    "author": "Ibrahim Rugova",
    "Translation": "To change a country, you need to change its people."
    },
    {
    "quote": "Shteti duhet të jetë një shtëpi e përbashkët për të gjithë qytetarët e tij.",
    "author": "Adem Demaçi",
    "Translation": "The state should be a common home for all its citizens."
    },
    {
    "quote": "Harresa nuk shëron asgjë, vetëm dashuria dhe mirësia mund të shërojnë.",
    "author": "Mother Teresa",
    "Translation": "Forgetting doesn't heal anything, only love and kindness can heal."
    },
    {
    "quote": "Shumica mund të jenë të kundërtën e atyre që besojnë, por kjo nuk do të thotë që ata kanë të drejtë.",
    "author": "Adem Demaçi",
    "Translation": "The majority may hold the opposite of those who believe, but that does not mean they are right."
    },
    {
    "quote": "Libri është një nga miqtë më të mirë që mund të kesh, dhe të gjithë mund ta kenë atë.",
    "author": "Ismail Kadare",
    "Translation": "The book is one of the best friends you can have, and everyone can have it."
    },
    {
    "quote": "Jeta është si një shenjtor i mëndafshit, që nuk na thotë asgjë, por na dërgon në rrugën tonë.",
    "author": "Ismail Kadare",
    "Translation": "Life is like a mute guide, who doesn't tell us anything, but leads us on our way."
    },
    {
    "quote": "Mund të bëj shumë gjëra të mëdha në jetë, por asgjë nuk është më e madhe se dashuria për vendlindjen time.",
    "author": "Mother Teresa",
    "Translation": "I can do many great things in life, but nothing is greater than the love for my homeland."
    },
    {
    "quote": "Një vend që nuk i ndjek vlerat e tij nuk mund të ketë identitet të fortë.",
    "author": "Ismail Kadare",
    "Translation": "A country that does not follow its own values ​​cannot have a strong identity."
    },
    {
    "quote": "Mbi të gjitha, sot ne duhet të përpiqemi që të ndërtojmë një shoqëri që është nënë e të gjitha, që mbrojnë të gjithë, dhe që vlerëson të gjithë.",
    "author": "Ibrahim Rugova",
    "Translation": "Above all, today we must strive to build a society that is the mother of all, that protects everyone, and that values ​​everyone."
    },
    {
    "quote": "Asgjë nuk është e pamundur, njerëzit vetëm duhet të jenë të gatshëm për të punuar shumë fort për të arritur qëllimet e tyre.",
    "author": "Adem Jashari",
    "Translation": "Nothing is impossible, people just need to be willing to work very hard to achieve their goals."
    },
    {
    "quote": "E ardhmja është e jona, dhe ne duhet ta ndërtojmë atë bashkë me veten tonë.",
    "author": "Ibrahim Rugova",
    "Translation": "The future is ours, and we must build it together with ourselves."
    },
    {
    "quote": "Ne duhet të mbrojmë të drejtën për të folur lirshëm, dhe të jemi të gatshëm për të marrë përgjegjësitë për fjalët tona.",
    "author": "Ismail Kadare",
    "Translation": "We must defend the right to speak freely, and be willing to take responsibility for our words."
    },
    {
    "quote": "Kur ne ndihmojmë njëri-tjetrin, ne e bëjmë botën një vend më të mirë për të gjithë.",
    "author": "Mother Teresa",
    "Translation": "When we help each other, we make the world a better place for everyone."
    },
    {
    "quote": "Vendosja e një qëllimi është gjysma e betejës, duke punuar vazhdimisht për ta arritur është tjetra gjysmë.",
    "author": "Adem Demaçi",
    "Translation": "Setting a goal is half the battle, working continuously to achieve it is the other half."
    },
    {
    "quote": "Duket e pamundur derisa të bëhet.",
    "author": "Proverb Shqiptar",
    "Translation": "It seems impossible until it's done."
    },
    {
    "quote": "Ai që bën gabimin, është më afër së vërtetës se ai që nuk bën asgjë.",
    "author": "Proverb Shqiptar",
    "Translation": "He who makes a mistake is closer to the truth than he who does nothing."
    },
    {
    "quote": "Faleminderit zoti, nuk kam nevojë për më shumë.",
    "author": "Proverb Shqiptar",
    "Translation": "Thank you, Lord, I do not need more."
    },
    {
    "quote": "Fjala e mirë nuk ka kurrë fund.",
    "author": "Proverb Shqiptar",
    "Translation": "Good words never end."
    },
    {
    "quote": "Më e rëndësishmja është dashuria sesa ajo që të kushton.",
    "author": "Proverb Shqiptar",
    "Translation": "Love is more important than what it costs you."
    },
    {
    "quote": "Fyerja nuk zgjidh asgjë, thjesht tregon dobësinë tënde.",
    "author": "Proverb Shqiptar",
    "Translation": "Insults solve nothing, they just show your weakness."
    },
    {
    "quote": "Malli i shtëpisë së mëparshme nuk ndalet kurrë.",
    "author": "Proverb Shqiptar",
    "Translation": "The longing for the old home never stops."
    },
    {
    "quote": "Frika është miku im i vetëm, sepse më tregon se sa unë jam i rrezikshëm.",
    "author": "Proverb Shqiptar",
    "Translation": "Fear is my only friend, because it tells me how dangerous I am."
    },
    {
    "quote": "Asgjë nuk është e përfunduar derisa të gjithë kenë qenë të kënaqur.",
    "author": "Proverb Shqiptar",
    "Translation": "Nothing is finished until everyone is satisfied."
    },
    {
    "quote": "Jeta është një fushë beteje, dhe ti duhet të luftosh për çdo centimeter.",
    "author": "Proverb Shqiptar",
    "Translation": "Life is a battlefield, and you have to fight for every inch."
    },
    {
    "quote": "Fjalët janë si shigjeta, kur të lëshohen, nuk mund të hiqen më.",
    "author": "Proverb Shqiptar",
    "Translation": "Words are like arrows, once released, they cannot be taken back."
    },
    {
    "quote": "E gjithë faji i njeriut është se ndjehet shumë.",
    "author": "Proverb Shqiptar",
    "Translation": "The whole fault of man is that he feels too much."
    },
    {
    "quote": "E para të marrësh detyrën, pastaj ta mbajësh mendimin.",
    "author": "Proverb Shqiptar",
    "Translation": "First take the job, then think about it."
    },
    {
    "quote": "Familja është e para dhe e fundit.",
    "author": "Proverb Shqiptar",
    "Translation": "Family comes first and last."
    },
    {
    "quote": "Fjalët janë si barutë, duhet t'u përdorë me kujdes.",
    "author": "Proverb Shqiptar",
    "Translation": "Words are like gunpowder, they must be used with care."
    },
    {
    "quote": "I krisur nuk është as gjysma e bërë.",
    "author": "Proverb Shqiptar",
    "Translation": "A stitch in time saves nine."
    },
    {
    "quote": "Kapja e frymës nuk llogaritet si punë.",
    "author": "Proverb Shqiptar",
    "Translation": "Breathing doesn't count as work."
    },
    {
    "quote": "Koha nuk pret askënd.",
    "author": "Proverb Shqiptar",
    "Translation": "Time waits for no one."
    },
    {
    "quote": "Mallkuar qoftë ai që luan me zjarrin.",
    "author": "Proverb Shqiptar",
    "Translation": "Cursed is he who plays with fire."
    },
    {
    "quote": "Ama ti nuk bën për të ndjekur gjurmët e hirushes sënde, bën për të ndjekur gjurmët e atyre që ikan përpara teje.",
    "author": "Proverb Shqiptar",
    "Translation": "But you do not walk in the footsteps of your old age, you walk in the footsteps of those who have gone ahead of you."
    },
    {
    "quote": "Ç'është e mirë në gojë nuk është e mirë në zemër.",
    "author": "Proverb Shqiptar",
    "Translation": "What is good in the mouth is not necessarily good in the heart."
    },
    {
    "quote": "Dashurinë dhe kujdesin nuk mund t'i blenë me para.",
    "author": "Proverb Shqiptar",
    "Translation": "Love and care cannot be bought with money."
    },
    {
    "quote": "Dhuratat me shumë gjëra mësojnë.",
    "author": "Proverb Shqiptar",
    "Translation": "Gifts teach many things."
    },
    {
    "quote": "Dija nuk ka kurrë fund.",
    "author": "Proverb Shqiptar",
    "Translation": "Knowledge has no end."
    },
    {
    "quote": "Dija është pasuri më e madhe.",
    "author": "Proverb Shqiptar",
    "Translation": "Knowledge is the greatest wealth."
    },
    {
    "quote": "Nuk ka djall pa faqe.",
    "author": "Proverb Shqiptar",
    "Translation": "There is no devil without a face."
    },
    {
    "quote": "E ka dhe dreqi pakushin.",
    "author": "Proverb Shqiptar",
    "Translation": "Even the devil has his favorite ones."
    },
    {
    "quote": "Fija e lartë, shokët e shtrëngojnë.",
    "author": "Proverb Shqiptar",
    "Translation": "The higher the ambition, the tighter the circle of friends."
    },
    {
    "quote": "Gjaku nuk lëshon gjë, veç gjak të dytë.",
    "author": "Proverb Shqiptar",
    "Translation": "Blood doesn't release anything, except for more blood."
    },
    {
    "quote": "Jo puna e ka fajin, por punëtori.",
    "author": "Proverb Shqiptar",
    "Translation": "It's not the work's fault, but the worker's."
    },
    {
    "quote": "Kujdesi i vogël shpëton nga shumë dëmtime.",
    "author": "Proverb Shqiptar",
    "Translation": "A little care can prevent a lot of damage."
    },
    {
    "quote": "Fjala më e mirë është heshtja.",
    "author": "Proverb Shqiptar",
    "Translation": "The best word is silence."
    },
    {
    "quote": "Kush në mur shtyn, veten e godet.",
    "author": "Proverb Shqiptar",
    "Translation": "He who pushes against the wall, hits himself."
    },
    {
    "quote": "Vetëm faji ka krahë të gjata.",
    "author": "Proverb Shqiptar",
    "Translation": "Only fault has long arms."
    },
    {
    "quote": "Kur nuk ka punë, nuk ka edhe mik.",
    "author": "Proverb Shqiptar",
    "Translation": "When there is no work, there are no friends either."
    },
    {
    "quote": "Një sy i urtë i njeh një qind sytë e budallait.",
    "author": "Proverb Shqiptar",
    "Translation": "One wise eye recognizes a hundred foolish eyes."
    },
    {
    "quote": "Kujdesi, armiku i madh i gabimeve.",
    "author": "Proverb Shqiptar",
    "Translation": "Carefulness, the great enemy of mistakes."
    },
    {
    "quote": "Shumica e gjërave nuk janë siç duken.",
    "author": "Proverb Shqiptar",
    "Translation": "Most things are not as they seem."
    },
    {
    "quote": "Fshati më afër, zëri më larg.",
    "author": "Proverb Shqiptar",
    "Translation": "The closer the village, the further the voice."
    },
    {
    "quote": "Kur nuk dihet, thuhet.",
    "author": "Proverb Shqiptar",
    "Translation": "When it is not known, it is said."
    },
    {
    "quote": "Kur nuk ka dinak, djali bëhet hajdut.",
    "author": "Proverb Shqiptar",
    "Translation": "When there is no wise man, the boy becomes a thief."
    },
    {
    "quote": "Nuk është në dorën e njeriut të gjithçka.",
    "author": "Proverb Shqiptar",
    "Translation": "Not everything is in the hands of man."
    },
    {
    "quote": "Gomari i ngatërrohet bylbylit.",
    "author": "Proverb Shqiptar",
    "Translation": "The donkey gets confused by the saddle."
    },
    {
    "quote": "Përse të shikojësh borën tek fqinja, kur vetes tënde nuk i bie era?",
    "author": "Proverb Shqiptar",
    "Translation": "Why look at the snow in your neighbor's yard when you don't even feel the wind in your own?"
    },
    {
    "quote": "Shoqëria e mirë në mëngjes, ndryshon në mesditë.",
    "author": "Proverb Shqiptar",
    "Translation": "Good company in the morning, changes in the afternoon."
    },
    {
    "quote": "Kush shumë shpreson, shpesh herë zhgënjehet.",
    "author": "Proverb Shqiptar",
    "Translation": "Who hopes too much, often gets disappointed."
    },
    {
    "quote": "Ashtu si përkulet dielli para hënës, kështu duhet të përkulet gjithmonë i fortëri para i dobëtit.",
    "author": "Proverb Shqiptar",
    "Translation": "Just as the sun bows down to the moon, so should the strong always bow down to the weak."
    },
    {
    "quote": "Zoti nuk ia bën shkopin atij që nuk mund ta mbajë.",
    "author": "Proverb Shqiptar",
    "Translation": "God doesn't give a whip to someone who can't hold it."
    },
    {
    "quote": "Në punë të gjata, syri vështrojnë më pak se mendja.",
    "author": "Proverb Shqiptar",
    "Translation": "In long works, the eyes observe less than the mind."
    },
    {
    "quote": "Vështirë është të qeshësh vetëm në këtë botë.",
    "author": "Proverb Shqiptar",
    "Translation": "It's difficult to laugh alone in this world."
    },
    {
    "quote": "Kush i thotë punës së tij \"të vogël\", atij i duhet të shohë mes të vegjëlave.",
    "author": "Proverb Shqiptar",
    "Translation": "Whoever calls his work 'small', must see the small ones."
    },
    {
    "quote": "Shpirti i mirë nuk blehet me para.",
    "author": "Proverb Shqiptar",
    "Translation": "A good soul cannot be bought with money."
    },
    {
    "quote": "Në shpirtin e njeriut ka hije si në pyjet e stinës.",
    "author": "Proverb Shqiptar",
    "Translation": "In the human spirit there are shadows as in the forests of the seasons."
    },
    {
    "quote": "Fjala është argjend, heshtja është art.",
    "author": "Proverb Shqiptar",
    "Translation": "Speech is silver, silence is golden."
    },
    {
    "quote": "Një turp i vogël me shumë zë bëhet turp i madh.",
    "author": "Proverb Shqiptar",
    "Translation": "A small shame with much noise becomes a big shame."
    },
    {
    "quote": "Shumë lëndë nuk pëlhurin, por shumë fjalë po.",
    "author": "Proverb Shqiptar",
    "Translation": "Many things do not spoil the skin, but many words do."
    },
    {
    "quote": "Kushtet e punës nuk kërkojnë besnikëri, por kanë të drejtë të kërkojnë korrektësi.",
    "author": "Proverb Shqiptar",
    "Translation": "The conditions of work do not require loyalty, but have the right to demand correctness."
    },
    {
    "quote": "Kur mosha hap derrat, të vjetrit mbyten.",
    "author": "Proverb Shqiptar",
    "Translation": "When age opens the doors, the old drown."
    },
    {
    "quote": "Sa më shumë të dish, aq më shumë të duket se nuk di gjë.",
    "author": "Proverb Shqiptar",
    "Translation": "The more you know, the more it seems like you don't know anything."
    }
  ]; //List of all Albanian quotes