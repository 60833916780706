import React, { useState, useEffect } from 'react';
import FadeIn from './FadeIn';
import { quotes } from '../quotes';

const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
};

const About = () => {
  const [randomQuote, setRandomQuote] = useState(null);
  
    useEffect(() => {
      setRandomQuote(getRandomQuote());
    }, []);
  
    return (
    <section
      id="about"
      className="bg-blue min-h-[90%] xl:h-[90vh] w-screen p-4 bg-cover bg-center relative border-b-4 border-black"
      style={{
        backgroundImage: `url('/books_bg.png')`,
      }}
    >
    <FadeIn delay='0.5s' direction={90} className="hidden md:block absolute bottom-0 sm:left-[5%] left-[10%] xl:left-[10%]">
        <img
          src="/drawing.png"
          alt="drawing"
          width={334}
          height={464}
        />
      </FadeIn>
      <FadeIn delay='0s'>
        <div className="container mx-auto h-full flex md:justify-end items-center">
          <div className="w-full md:w-3/5 bg-news shadow-lg text-black p-4 mr-[-40px] md:mr-8">
            <div className="text-center mb-4">
              <div className="border-t-4 border-black my-1"></div>
              <h2 className="text-4xl font-headline">FROM THE EXODUS TO THE UNITED NATIONS</h2>
              <div className="border-t-2 border-black my-1"></div>
              </div>
            <div className="text-sm font-times text-justify about-text">
              <p>
                Born in the picturesque town of Prizren, Kosovo, Arife Muji faced immense hardship 
                growing up in poverty and surviving the genocide perpetrated by Serbs against Albanians. 
                Despite these challenges, she rose above her circumstances, fueled by an unwavering 
                commitment to help others and make an impact in the world.
              </p>
              <br/>
              <p>
                With determination and dedication, Arife got awarded the joint Penta scolarship from EU Commission and 
                obtained three degrees – a testament to her commitment to academic excellence. Her accolades 
                include a BA in French Language & Literature and a BA in Political Sciences from the University of Pristina, 
                as well as an MSc in European Politics from Masaryk University in the Czechia.
              </p>
              <br/>
              <h2 className="text-xl font-bold text-left">A Professional Journey</h2>
              <p>
                From her humble beginnings, Arife embarked on an impressive career in International 
                Organizations, showcasing her expertise in political and security-related issues. 
                Her work in research, policy advising, and television appearances highlights her strong 
                morals and her ability to navigate complex political landscapes with grace and talent.
              </p>
              <br/>
            <img src="/newspaper.png" alt="Miss Arife Muji in office" width={300} height={300} className='mx-auto'/>
            <p className='italic text-center'>Miss Arife Muji in office</p>
            <br/>
              <p>
                In the field of Security and as a Policy Adviser to the Ministry, 
                Arife demonstrated her ability to influence policy and drive change in her home country. 
                Her work focused on addressing pressing issues, advocating for those who have experienced hardship 
                and displacement.
              </p>
              <br/>
              <p>
                While coordinating migration-related projects for United Nations, Arife has made a significant 
                impact on the lives of migrants and returnees. Her empathetic nature and strong interpersonal skills 
                have enabled her to connect with people from diverse backgrounds, fostering collaboration and unity in her work environments.
              </p>
            <div className="border-t-2 border-black my-1"></div>
            {randomQuote && (
                <a href="https://www.arifemuji.com/your-albanian-quote" target="_blank" rel="noopener noreferrer">
                  <q className='italic text-right font-bold'>{randomQuote.Translation}</q>
                  <p className='text-right font-bold'>{` - ${randomQuote.author}`}</p>
                </a>
              )}
            <div className="border-t-2 border-black my-1"></div>
              <p>
                In addition to her professional accomplishments, Arife is an avid marathon runner, book lover, and artist,
                showcasing her diverse talents and multifaceted nature. Her personal story serves as an inspiration for
                many, demonstrating the power of resilience and the human spirit in overcoming adversity and making a
                difference in the world. With her unique blend of determination, strong morals, and talent, Arife continues
                to break barriers and inspire those around her to do the same.
              </p>
              <br/>
              <p className='text-right font-bold'>by Gianluca Fornaciari</p>
            </div>
            <div className="border-b-4 border-black my-2"></div>
          </div>
        </div>
        </FadeIn>
        <FadeIn delay='0.5s'>
        <div className='mx-auto w-[40vw] min-w-[200px] md:hidden relative bottom-0 mb-[-5%]'>
        <img
            src="/drawing.png"
            alt="drawing"
            width={334}
            height={464}
          />
        </div>
        </FadeIn>
    </section>
  );
};

export default About;
