import React from "react";
import FadeIn from "./FadeIn";
import { useEffect } from "react";

const BlueStar = () => {
  return (
    <span className="text-blue text-3xl md:text-4xl relative z-10 xl:text-6xl">
      &#9733;
    </span>
  );
};

const Hero = () => {
  const starPositions = [
    { top: "86%", left: "55%" },
    { top: "84%", left: "63%" },
    { top: "81%", left: "70%" },
    { top: "75%", left: "76%" },
    { top: "68%", left: "80%" },
    { top: "59%", left: "83%" },
  ];

  const starElements = starPositions.map((pos, index) => (
    <FadeIn
      key={index}
      delay={`${0.3 * (index + 1)}s`}
      style={{
        position: "absolute",
        top: pos.top,
        left: pos.left,
        zIndex: 1,
      }}
    >
      <BlueStar />
    </FadeIn>
  ));

  useEffect(() => {
    const contactElement = document.getElementById("contact");
    const handleButtonClick = () => {
      contactElement?.scrollIntoView({ behavior: "smooth" });
    };

    const button = document.getElementById("connect-button");
    button?.addEventListener("click", handleButtonClick);

    return () => {
      button?.removeEventListener("click", handleButtonClick);
    };
  }, []);

  return (
    <div id="home" className="flex flex-col-reverse md:flex-row min-h-screen items-center border-b-4 pt-10 md:pt-40 justify-center md:flex-row md:justify-between md:items-start">
      <div className="text-center w-full px-10 mt-16 md:mt-0 md:pl-[5vw] md:text-left md:w-1/2">
        <FadeIn delay="0s">
          <div>
            <h1 className="font-lato text-3xl text-black xl:text-7xl mb-4 leading-none">
              Welcome,
            </h1>
          </div>
        </FadeIn>
        <FadeIn delay="0.5s">
          <div>
            <h1 className="font-lato text-3xl text-black xl:text-7xl mb-4 leading-none">
              I am{" "}
              <span className="text-blue font-indie font-bold">
                Arife Muji
              </span>
              ,
            </h1>
          </div>
        </FadeIn>
        <FadeIn delay="1s">
          <div>
            <h1 className="font-lato text-2xl text-black xl:text-6xl mb-4 leading-none">
              and I believe through diplomacy, we can create a more stable, fulfilling world.
            </h1>
          </div>
        </FadeIn>
        <FadeIn delay="1.5s">
          <button className="bg-blue my-5 md:my-10 font-bold mb-[70px] md:mb-0 text-white font-indie text-3xl xl:text-4xl px-6 py-3 rounded-full focus:outline-none hover:bg-ruby hover:text-white transition-colors">
            <a href="#contact">Let&apos;s connect</a>
          </button>
        </FadeIn>
      </div>
      <FadeIn delay="0s" direction={270} className="flex items-center">
      <div className="relative w-full max-w-[600px] mt-10 xl:mt-5 mb-[-50px] px-[20%] md:px-[15%] xl:max-w-[750px] xl:mr-30">
        {/*starElements*/}
        <img
          src="/hero-image4.png"
          alt="Arife's hero pose in front of a stylised kosovo flag"
          className="xl:mt-[-70px]"
        />
      </div>
      </FadeIn>
    </div>
  );
};

export default Hero;