import React from "react";
import { Helmet } from "react-helmet";
import FadeIn from "../components/FadeIn";
import ReactGA from 'react-ga';

ReactGA.initialize('G-Z5Z5MNVEWH');

const ThankYou = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="text-center py-20 px-20 bg-white w-screen h-screen overflow-hidden">
      <Helmet>
        <title>Arife Muji thanks... you!</title>
      </Helmet>
      <FadeIn delay="0s">
        <h1 className="text-5xl font-bold mb-4 text-lato">Thank You!</h1>
      </FadeIn>
      <FadeIn delay="0.3s">
        <p className="text-xl mb-5 text-lato">
          Your message has been sent successfully. <br/> I'll get back to you shortly.
        </p>
      </FadeIn>
      <FadeIn delay="0.6s">
          <a href="/" className="text-blue-500 underline">
            <button className="bg-blue my-5 md:my-5 font-bold text-white font-indie text-xl xl:text-2xl px-6 py-3 rounded-full focus:outline-none hover:bg-ruby hover:text-white transition-colors">
              Go back to the homepage
            </button>
          </a>
      </FadeIn>
      <FadeIn delay="0.9s">
        <div className="flex justify-center items-center xl:mt-5">
          <img
            src="/marathon1.png"
            alt="Arife victory pose"
            className="max-w-[300px]~ mx-auto"
          />
        </div>
      </FadeIn>
    </div>
  );
};

export default ThankYou;
