import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { ReactComponent as Logo } from './logo.svg';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setTimeout(toggleMenu, 300);
  };

  return (
    <header id="navbar" className="bg-white py-2 md:py-4 fixed w-full z-10 min-h-[60px] h-[10vh]">
      <div className="container mx-auto px-4 flex justify-between items-center flex-col md:flex-row">
        <div className="w-full md:w-auto text-center md:text-left flex items-center justify-between">
          <a href="https://www.arifemuji.com" className="mx-auto md:mx-0 md:absolute md:left-[5vw] md:top-[2vh]" style={{ display: 'inline-block' }}>
            <Logo width="200px" height="50px" className="mt-2 md:max-w-none hover-fill-red transition-colors duration-200"/>
          </a>
          <button
            className="md:hidden p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white text-black focus:ring-indigo-500"
            onClick={toggleMenu}
          >
            {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>
        <nav
          className={`${
            isOpen ? 'block bg-white' : 'hidden'
          } md:block space-y-4 md:space-x-4 md:space-y-0 w-full md:w-auto mt-3 text-center md:text-lg whitespace-nowrap`}
        >
          {[
            'home',
            'about',
            'accomplishments',
            'blog',
            'services',
            'contact',
          ].map((item) => (
            <a
              key={item}
              href={`#${item}`}
              className="font-lato text-black text-lg block md:inline-block relative ml-3 group hover:text-ruby transition-colors duration-200"
              onClick={handleLinkClick}
            >
              {item}
              <span className="absolute left-1/2 bottom-0 h-0 w-0 bg-blue group-hover:h-[2px] group-hover:w-full transition-all duration-200 ease-in-out transform -translate-x-1/2 scale-x-100 group-hover:scale-x-100"></span>
            </a>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
