import React from 'react';
import FadeIn from './FadeIn';

const Services = () => {
  return (
    <section
      id="services"
      className="min-h-[90%] md:h-[90vh] w-screen p-8 bg-cover overflow-hidden bg-center relative z-0 bg-blue border-b-4 border-black"
      style={{
        backgroundImage: `url('/services_background2.png')`,
      }}
    >
      <div className="container mx-auto h-full flex flex-col items-center relative z-[2]">
        <h2 className="text-5xl font-lato font-bold text-white mb-8">Services</h2>
        <div className="relative w-full">
          <FadeIn delay="0s">
            <div
              className="bg-white shadow-lg mx-auto w-[90%] p-4 flex flex-col md:absolute md:w-[30%] md:left-[-2%] z-[2] md:top-0 rotate-[-3deg] transform hover:scale-110 transition-transform duration-300"
            >
              <h3 className="text-3xl font-bold mb-2 font-indie text-ruby">Consulting</h3>
              <p className="text-black">
                I offer expert consulting services, helping organizations navigate complex political landscapes and make informed decisions.
              </p>
              <img
                src="/service1.jpg"
                alt="Consulting and Policy Advising"
                width={640}
                height={480}
                className="w-full mt-4"
              />
            </div>
          </FadeIn>
          <FadeIn delay={window.innerWidth > 768 ? "0.3s" : "0s"}>
            <div
              className="bg-white shadow-lg mx-auto w-[90%] p-4 flex flex-col mt-8 md:absolute md:left-[31%] md:w-[30%] z-[2] rotate-[4deg] transform hover:scale-110 transition-transform duration-300"
            >
              <h3 className="text-3xl font-bold mb-2 font-indie text-ruby">Project Management</h3>
              <p className="text-black">
                With a proven track record of successful project management for International Organisations, I can lead your projects from conception to completion, ensuring timely and efficient delivery.
              </p>
              <img
                src="/service2.jpg"
                alt="Project Management"
                width={640}
                height={424}
                className="w-full mt-4"
              />
            </div>
          </FadeIn>
          <FadeIn delay={window.innerWidth > 768 ? "0.6s" : "0s"}>
            <div
              className="bg-white shadow-lg mx-auto w-[90%] p-4 flex flex-col mt-8 md:absolute md:mt-0 md:left-[64%] md:w-[40%] z-[2] rotate-[-2deg] transform hover:scale-110 transition-transform duration-300"
            >
              <h3 className="text-3xl font-bold mb-2 font-indie text-ruby">Public Speeches</h3>
              <p className="text-black">
                As an accomplished public speaker, I am available for speaking engagements on a variety of topics, including political issues, human rights, and my personal journey thriving as a woman in a world of men.
              </p>
              <img
                src="/service3.png"
                alt="Public Speeches"
                width={670}
                height={510}
                className="w-full mt-4"
              />
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
};

export default Services;
