import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';

const FadeIn = ({ start, delay, direction = 180, scale = 1, fadeOutDirection, fadeOut = false, repeat = '1', visibleDuration = '1s', hiddenDuration = '1s', fadeInDuration = '1s', fadeOutDuration = '0.5s', className = '', children, style }) => {
  const radiansIn = (direction * Math.PI) / 180;
  const xOffsetIn = Math.sin(radiansIn) * 50;
  const yOffsetIn = -Math.cos(radiansIn) * 50;

  const fadeOutDirectionFinal = fadeOutDirection !== undefined ? fadeOutDirection : direction;
  const radiansOut = (fadeOutDirectionFinal * Math.PI) / 180;
  const xOffsetOut = Math.sin(radiansOut) * 50;
  const yOffsetOut = -Math.cos(radiansOut) * 50;

  const [animationName, setAnimationName] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (typeof window !== 'undefined') {
        const totalDuration = parseFloat(visibleDuration) + parseFloat(hiddenDuration) + parseFloat(fadeInDuration) + parseFloat(fadeOutDuration);
        const fadeInEndPercentage = (parseFloat(fadeInDuration) / totalDuration) * 100;
        const visibleEndPercentage = fadeInEndPercentage + ((parseFloat(visibleDuration) / totalDuration) * 100);
        const fadeOutEndPercentage = visibleEndPercentage + ((parseFloat(fadeOutDuration) / totalDuration) * 100);
        const uniqueID = `fadeIn-${Math.random().toString(36).substr(2, 9)}`;
        const fadeInAnimation = `
          @keyframes ${uniqueID} {
            0% {
              opacity: 0;
              transform: translate(${xOffsetIn}px, ${yOffsetIn}px);
            }
            ${fadeInEndPercentage}% {
              opacity: 1;
              transform: translate(0, 0);
            }
            ${visibleEndPercentage}% {
              opacity: 1;
              transform: translate(0, 0);
            }
            ${fadeOutEndPercentage}%, 100% {
              opacity: ${fadeOut ? '0' : '1'};
              transform: translate(${fadeOut ? xOffsetOut + 'px' : '0'}, ${fadeOut ? yOffsetOut + 'px' : '0'});
              transform: scale(${scale});
            }
          }
        `;
        setAnimationName(uniqueID);

        const styleElement = document.createElement('style');
        styleElement.innerHTML = fadeInAnimation;
        document.head.appendChild(styleElement);

        return () => {
          document.head.removeChild(styleElement);
        };
      }
    }
  }, [isMounted, direction, fadeOutDirection, fadeOut, visibleDuration, hiddenDuration, fadeInDuration, fadeOutDuration]);

  if (!isMounted) return null;

  return (
    <InView triggerOnce threshold={0.1}>
      {({ inView, ref }) => {
        const totalDuration = parseFloat(visibleDuration) + parseFloat(hiddenDuration) + parseFloat(fadeInDuration) + parseFloat(fadeOutDuration);
        return (
          <div
            ref={ref}
            className={`opacity-0 ${start || inView ? 'animate' : ''} ${className}`}
            style={{
              ...style,
              animationName: start || inView ? animationName : '',
              animationDelay: delay,
              animationDirection: 'normal',
              animationDuration: `${totalDuration}s`,
              animationTimingFunction: 'ease',
              animationFillMode: 'forwards',
              animationIterationCount: inView ? repeat : '1',
            }}
          >
            {children}
          </div>
        );
      }}
    </InView>
  );
};

export default FadeIn;
