import React, { Suspense } from 'react';
import { Helmet } from "react-helmet";
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import Blog from '../components/Blog';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import ReactGA from 'react-ga';

const Achievements = React.lazy(() => import('../components/Achievements'));

ReactGA.initialize('G-Z5Z5MNVEWH');
  
const Home = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="bg-white min-h-screen scroll-container">
      <Helmet>
        <title>Arife Muji - Professional</title>
        <meta name="description" content="Passionate researcher, educator, and trainer specializing in international politics and migration. Navigate the complexities of global affairs with insights from an expert in the field. Committed to fostering understanding through education and training for a world shaped by diversity and interconnectedness."/>
      </Helmet>
      <Header />
      <div className="scroll-section">
        <Suspense fallback={<div className='text-blue font-indie text-center'>Loading...</div>}>
        <Hero />
        </Suspense>
      </div>
      <div className="scroll-section"><About /></div>
      <div className="scroll-section">
        <Suspense fallback={<div className='text-blue font-indie text-center'>Loading...</div>}>
          <Achievements />
        </Suspense>
      </div>
      <div className="scroll-section"><Blog /></div>
      <div className="scroll-section"><Services /></div>
      <div className="scroll-section"><Contact /></div>
      <div className="scroll-end"><Footer /></div>
    </div>
  );
};

export default Home;
