import React, { useState } from 'react';
import FadeIn from './FadeIn';

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    message: '',
    category: '',
    other: '',
    phone: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div id="contact" style={{ zIndex: 0 }} className={`grid md:grid-cols-2 pt-0 bg-ruby text-white relative xl:h-[80vh] overflow-hidden ${formData.category === 'other' && 'border-r-0 md:border-r-2'}`}>
      <div className="md:pb-0 pb-8 text-center md:text-left md:order-2 p-8">
        <h2 className="text-4xl ">Contact me</h2>
        <form action="https://formsubmit.co/am@arifemuji.com" method="POST" className="space-y-4" style={{ zIndex: 1 }}> 
          <input type="hidden" name="_subject" value={`${formData.name} messaged you!`}/>
          <input type="hidden" name="_next" value="https://arifemuji.com/thank-you"/>
          <input
            type="text"
            name="name"
            placeholder="Your full name (required)"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 bg-white rounded text-black"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your email (required)"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 bg-white rounded text-black"
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Your phone number (optional)"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 bg-white rounded text-black"
          />
          <textarea
            name="message"
            placeholder="Your message (required)"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 bg-white rounded text-black"
            rows={3}
            required
          />
          <div className="space-y-2">
            <label className="font-bold">Reason (required):</label>
            <div className="flex items-center ml-[20%] md:ml-0">
              <input
                type="radio"
                id="general-contact"
                name="category"
                value="general-contact"
                onChange={handleChange}
                required
              />
              <label htmlFor="general-contact" className="ml-2">
                General inquiry
              </label>
            </div>
            <div className="flex items-center ml-[20%] md:ml-0">
              <input
                type="radio"
                id="collaborations"
                name="category"
                value="collaborations"
                onChange={handleChange}
              />
              <label htmlFor="collaborations" className="ml-2">
                Collaboration
              </label>
            </div>
            <div className="flex items-center ml-[20%] md:ml-0">
              <input
                type="radio"
                id="other"
                name="category"
                value="other"
                onChange={handleChange}
                />
                <label htmlFor="other" className="ml-2">
                    Other
                </label>
                {formData.category === 'other' && (
                <input
                    type="text"
                    name="other"
                    placeholder="Specify other category"
                    value={formData.other}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 bg-white rounded text-black ml-4 h-8"
                    required
                    />
                )}
                </div>
            </div>
            <button
                type="submit"
                className="bg-blue my-5 md:my-10 font-bold text-white font-indie text-3xl xl:text-4xl px-6 py-3 rounded-full focus:outline-none hover:bg-white hover:text-black transition-colors"
            >
                Submit
            </button>
        </form>
      </div>
      <div className="text-center relative md:text-left my-[5vh] md:order-1 md:border-r-2 px-8 md:h-[70vh] h-[80vh]">
        <hr className="block md:hidden mt-0 h-4" />
        <h2 className="text-4xl mb-4">Schedule a meeting</h2>
        <p>
          Click the button below to schedule an appointment with me.
        </p>
        <p className="text-sm italic">
          [Please state <b>CLEARLY</b> the purpose of the meeting otherwise it would not be taken into consideration]
        </p>
        <button
          onClick={() => window.open('https://calendly.com/arifemuji', '_blank')}
          className="bg-blue my-5 md:my-10 font-bold text-white font-indie text-3xl xl:text-4xl px-6 py-3 rounded-full focus:outline-none hover:bg-white hover:text-black transition-colors"
        >
          Request
        </button>
        <FadeIn delay="0.3s" style={{ zIndex: -1 }} className="relative h-full bottom-[-15vh] md:bottom-0 min-w-[120px] left-1/2 md:left-[25%] transform -translate-x-1/2 md:transform-none">
        <div >
          <img src="/reporter.png" alt="Reporter" width={185} height={185} className="w-[160px] h-[395px] xl:w-[176px] xl:h-[430px]" />
        </div>
        </FadeIn>
      </div>
    </div>
  );
};

export default Contact;