import React, { useEffect, useState } from "react";
import LinesEllipsis from 'react-lines-ellipsis';
import FadeIn from './FadeIn';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [imageIndex, setImageIndex] = useState(7);
  const [titleIndex, setTitleIndex] = useState(16);

  useEffect(() => {
    const fetchArticles = async () => {
      const response = await fetch(
        "https://arifemuji.com/blog/wp-json/wp/v2/posts?_embed&per_page=27"
        //"https://longtourist.com/wp-json/wp/v2/posts?_embed&per_page=27"
      );
      const data = await response.json();

      const fetchedArticles = data.map((post) => {
        const title = post.title.rendered;
        const url = post.link;
        const excerpt = cleanExcerpt(post.excerpt.rendered);
        const lineClamp = randomLineClamp();

        return { title, url, excerpt, lineClamp };
      });

      setArticles(fetchedArticles);
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const setIndexValues = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setImageIndex(5);
        setTitleIndex(11);
      } else if (width >= 768 && width <= 1280) {
        setImageIndex(6);
        setTitleIndex(12);
      } else {
        setImageIndex(7);
        setTitleIndex(18);
      }
    };

    setIndexValues();
    window.addEventListener('resize', setIndexValues);
    return () => window.removeEventListener('resize', setIndexValues);
  }, []);

  const cleanExcerpt = (excerpt) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = excerpt;
    const cleanedExcerpt = tempElement.textContent || tempElement.innerText || "";
    return cleanedExcerpt;
  };

  const randomLineClamp = () => {
    return Math.floor(Math.random() * 3) + 2;
  };
  return (
    <section id="blog" className="relative w-screen min-h-[90vh] md:h-[90vh] bg-ruby p-4 overflow-hidden border-b-4 border-black">
      <div className="relative h-full space-x-4 mt-[-0%] rotate-[-2deg] scale-[1.10]">
        <div className="columns-2 md:columns-4 xl:columns-5 gap-4">
          {articles.map((article, index) => {
            const adjustedIndex = index >= titleIndex ? index - 2
              : index >= imageIndex ? index - 1
              : index;
            const articleToShow = articles[adjustedIndex];

            if (index === titleIndex) {
              return (
                <p key={index} className="font-headline text-8xl text-white mb-4 text-right">BLOG</p>
              );
            }
            if (index === imageIndex) {
              return (
              <FadeIn key={index} delay={`${0.05*index}s`}>
                <a href="https://sbunker.net/autori/arife.muji/">
                  <img src="sbunker_face.png" alt="sbunker face" width={300} height={300} className="relative mb-4 border-b-4 border-t-4 transition-all duration-300 transform hover:scale-110 hover:z-10"/>
                </a>
              </FadeIn>
              )
            }
              return (
                <FadeIn key={index} delay={`${0.05 * index}s`}>
                  <div className={`bg-white p-4 shadow-lg mb-4 transition-all duration-300 transform hover:scale-110 z-1 hover:z-10 ${index >= 18 && "hidden md:block"}`}>
                    <a
                      key={articleToShow.title}
                      href={articleToShow.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3 className="text-2xl text-ruby font-indie font-bold mb-2 line-clamp-1">
                        {articleToShow.title}
                      </h3>
                      <LinesEllipsis
                        className="text-base font-lato text-black break-all"
                        text={articleToShow.excerpt}
                        maxLine={articleToShow.lineClamp}
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </a>
                  </div>
                </FadeIn>
              );
            })}
          </div>
        </div>
      </section>
    );
    };
    
    export default Blog;