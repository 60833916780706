import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black min-h-[150px] text-white py-4 md:h-[20%]">
      <div className="container mx-auto flex justify-center">
        <a href="https://www.facebook.com/muji.arife">
          <FaFacebook className="text-white mr-4 hover:text-gray-400" size={30} />
        </a>
        <a href="https://twitter.com/mujiarife">
          <FaTwitter className="text-white mr-4 hover:text-gray-400" size={30} />
        </a>
        <a href="https://www.instagram.com/miss_muji/">
          <FaInstagram className="text-white mr-4 hover:text-gray-400" size={30} />
        </a>
        <a href="https://www.linkedin.com/in/arife-muji-90280843/">
          <FaLinkedin className="text-white hover:text-gray-400" size={30} />
        </a>
      </div>
      <div className="text-center text-sm mt-4">
        © 2023 Arife Muji. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;